var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        {
          staticClass: "table_head",
          attrs: { type: "flex", justify: "center" }
        },
        [
          _c(
            "a-col",
            { staticClass: "mrgin_btm", attrs: { xs: 24, sm: 10, md: 18 } },
            [
              _c("h1", [_vm._v("Liste des attestations")]),
              _c("br"),
              !_vm.listeAttestationLoading
                ? _c(
                    "a-button",
                    {
                      staticClass: "editable-add-btn",
                      attrs: { type: "primary" },
                      on: { click: _vm.showModal }
                    },
                    [_vm._v("Nouvelle\n      ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("br"),
          _c("br"),
          _c("AttestationForm", {
            key: _vm.newDemandeAttestationComponentKey,
            ref: "attestationForm",
            attrs: {
              visible: _vm.attestationFormVisible,
              processing: _vm.attestationFormProcessing,
              isAdmin: true
            },
            on: {
              cancel: _vm.handleCancel,
              close: _vm.handleClose,
              create: _vm.handleCreate
            }
          }),
          _c(
            "a-col",
            { attrs: { xs: 24, sm: 24, md: 20 } },
            [
              _c("ListeAttestations", {
                attrs: {
                  attestations: _vm.attestations,
                  "is-admin": true,
                  processing: _vm.listeAttestationLoading,
                  user: _vm.user
                },
                on: {
                  edit: function($event) {
                    return _vm.onEdit($event)
                  },
                  print: function($event) {
                    return _vm.onPrint($event, _vm.id)
                  },
                  delete: function($event) {
                    return _vm.onDelete($event, _vm.id)
                  },
                  validate: function($event) {
                    return _vm.onValidate($event, _vm.id)
                  },
                  upload_success: function($event) {
                    return _vm.onUploadSuccess($event)
                  },
                  delete_file: function($event) {
                    return _vm.onFileDelete($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("necessary-paiement-message", {
        attrs: { visible: _vm.paiementErrorMessageModalVisible },
        on: { close: _vm.closePaiementErrorModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
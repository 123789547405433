var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            { attrs: { span: 22 } },
            [
              _c(
                "a-spin",
                { attrs: { tip: "Chargement...", spinning: _vm.processing } },
                [
                  _vm.attestations.length
                    ? _c("a-table", {
                        attrs: {
                          bordered: "",
                          dataSource: _vm.attestations,
                          columns: _vm.columns,
                          rowKey: _vm.id
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "row_index",
                              fn: function(text, record, index) {
                                return [_c("p", [_vm._v(_vm._s(index + 1))])]
                              }
                            },
                            {
                              key: "attestation",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    _vm._s(record.type_attestation.nom_fr) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "etat",
                              fn: function(text, record) {
                                return [
                                  record.etat ===
                                  _vm.ETAT_DEMANDE_ATTESTATION_EN_ATTENTE
                                    ? _c(
                                        "a",
                                        { staticStyle: { color: "orange" } },
                                        [_vm._v("En cours")]
                                      )
                                    : _c("a", [_vm._v("Prête")])
                                ]
                              }
                            },
                            {
                              key: "dt_demande",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    _vm._s(_vm._f("date")(record.created_at)) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "operation",
                              fn: function(text, record) {
                                return [
                                  record.etat !==
                                  _vm.ETAT_DEMANDE_ATTESTATION_ACCEPTER
                                    ? _c(
                                        "a-popconfirm",
                                        {
                                          attrs: {
                                            title:
                                              "Voulez-vous valider et notifier cette demande d'attestation ?"
                                          },
                                          on: {
                                            confirm: function($event) {
                                              return _vm.validateAttestation(
                                                record
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c("a-icon", {
                                                staticClass: "action_icons",
                                                attrs: {
                                                  type: "bell",
                                                  theme: "twoTone",
                                                  twoToneColor: "#52c41a"
                                                }
                                              }),
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    "Valider et notifier l'adherent"
                                                  )
                                                ]
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  record.etat ===
                                  _vm.ETAT_DEMANDE_ATTESTATION_ACCEPTER
                                    ? _c(
                                        "a-tooltip",
                                        { attrs: { placement: "top" } },
                                        [
                                          _c("template", { slot: "title" }, [
                                            _c("span", [_vm._v("Imprimer")])
                                          ]),
                                          _c("a-icon", {
                                            staticClass: "action_icons",
                                            attrs: {
                                              type: "printer",
                                              theme: "twoTone"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.getDocument(record)
                                              }
                                            }
                                          })
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  !record.etat
                                    ? _c(
                                        "a-popconfirm",
                                        {
                                          attrs: {
                                            title:
                                              "Etes-vous sûr de vouloir annuler la demande ?"
                                          },
                                          on: {
                                            confirm: function() {
                                              return _vm.onDelete(record.id)
                                            }
                                          }
                                        },
                                        [
                                          _c("a-icon", {
                                            staticClass:
                                              "action_icons decission_btn",
                                            attrs: {
                                              type: "delete",
                                              theme: "twoTone",
                                              twoToneColor: "#eb2f96"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "expandedRowRender",
                              fn: function(record) {
                                return record.observation
                                  ? _c(
                                      "div",
                                      { staticStyle: { margin: "0" } },
                                      [
                                        _c("b", [_vm._v(" Observation : ")]),
                                        _vm._v(
                                          _vm._s(record.observation) +
                                            "\n          "
                                        )
                                      ]
                                    )
                                  : _c("span", [_vm._v("Pas d'observation")])
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : _c("EmptyResult")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("necessary-paiement-message", {
        attrs: { visible: _vm.paiementErrorMessageModalVisible }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <a-row type="flex" justify="center" class="table_head">
      <a-col :xs="24" :sm="10" :md="18" class="mrgin_btm">
        <h1>Liste des attestations</h1>
        <br />
        <a-button
          class="editable-add-btn"
          type="primary"
          @click="showModal"
          v-if="!listeAttestationLoading"
          >Nouvelle
        </a-button>
      </a-col>
      <br />
      <br />
      <AttestationForm
          ref="attestationForm"
          :visible="attestationFormVisible"
          :processing="attestationFormProcessing"
          :isAdmin="true"
          @cancel="handleCancel"
          @close="handleClose"
          @create="handleCreate"
          :key="newDemandeAttestationComponentKey"
      />
      <a-col :xs="24" :sm="24" :md="20">
        <ListeAttestations
          :attestations="attestations"
          :is-admin="true"
          :processing="listeAttestationLoading"
          :user="user"
          @edit="onEdit($event)"
          @print="onPrint($event, id)"
          @delete="onDelete($event, id)"
          @validate="onValidate($event, id)"
          @upload_success="onUploadSuccess($event)"
          @delete_file="onFileDelete($event)"
        />
      </a-col>
    </a-row>
    <necessary-paiement-message
      :visible="paiementErrorMessageModalVisible"
      @close="closePaiementErrorModal"
    />
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import moment from "moment";


// import SearchComponent from "@/components/common/Search";
// import EmptyResult from "@/components/common/EmptyResult";
import ListeAttestations from "@/components/common/attestations/ListeAttestations";
import NecessaryPaiementMessage from "@/components/common/NecessaryPaiementMessage";
import AttestationForm from "@/components/common/attestations/AttestationEditor";

export default {
  name: "AttestationsIndex",
  created() {
    this.id = this.$route.params["id"];
    this.fetchData();
  },
  data() {
    return {
      attestationFormVisible: false,
      listeAttestationLoading: true,
      attestationFormProcessing: false,
      id: null,
      paiementErrorMessageModalVisible: false,
      newDemandeAttestationComponentKey: 0,
    };
  },

  components: {
    ListeAttestations,
    // EmptyResult,
    // SearchComponent,
    AttestationForm,
    NecessaryPaiementMessage,
  },
  computed: {
    ...mapState({
      organismes: (state) => state.adherent_organismes.organismes,
      attestations: (state) => state.attestations.attestations,
      pagination: (state) => state.attestations.pagination,
      user: (state) => state.adherents.selected_adherent,
    }),
  },
  methods: {
    closePaiementErrorModal() {
      this.paiementErrorMessageModalVisible = false;
    },
    showPaiementErrorModal() {
      this.paiementErrorMessageModalVisible = true;
    },
    fetchData() {
      this.getUser(this.id)
        .then(() => this.fetchUserAttestations({ user_id: this.id }))
        .finally(() => this.stoplisteAttestationLoading());
      this.$store.cache.dispatch("fetchAdherentOrganismes");
    },
    handleCreate() {
      this.startLoading();
      this.startlisteAttestationLoading();
      const form = this.$refs.attestationForm.form;
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        this.add({ ...form.getFieldsValue(), user_id: this.id })
          .then(() => {
            this.fetchUserAttestations({ user_id: this.id });
            this.handleClose();
            form.resetFields();
          })
          .finally(() => {
            this.stopLoading();
            this.stoplisteAttestationLoading();
          });
      });
    },
    getDemandeAttestationComponentKey() {
      this.newDemandeAttestationComponentKey = this.getRandomInt();
    },
    startLoading() {
      this.attestationFormProcessing = true;
    },
    stopLoading() {
      this.attestationFormProcessing = false;
    },
    startlisteAttestationLoading() {
      this.listeAttestationLoading = true;
    },
    stoplisteAttestationLoading() {
      this.listeAttestationLoading = false;
    },
    moment,
    closeModal() {
      this.attestationFormVisible = false;
      this.getDemandeAttestationComponentKey();
    },
    onDelete(attestationId) {
      this.startlisteAttestationLoading();
      this.delete(attestationId)
        .then(() => this.fetchUserAttestations({ user_id: this.id }))
        .finally(() => this.stoplisteAttestationLoading());
    },
    onValidate(attestationId) {
      this.startlisteAttestationLoading();
      this.update({
        id: attestationId,
        etat: this.ETAT_DEMANDE_ATTESTATION_ACCEPTER,
      })
        .then(() => this.fetchUserAttestations({ user_id: this.id }))
        .finally(() => this.stoplisteAttestationLoading());
    },
    onPrint(id) {
      this.startlisteAttestationLoading();
      this.callPdfGenerator({
        id: this.user.id,
        type: 7, //Attestation
        organisation: this.user.organisation_id,
      })
        .catch(() => this.$_throwAnError())
        .finally(() => this.stoplisteAttestationLoading());
    },
    showModal() {
      if (!this.isUserPaiementSettled(this.user)) {
        this.displayPaiementMandatoryError();
        return;
      }
      this.attestationFormVisible = true;
    },
    displayPaiementMandatoryError() {
      this.showPaiementErrorModal();
    },
    handleCancel(e) {
      this.attestationFormVisible = false;
    },
    handleClose(e) {
      this.attestationFormVisible = false;
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    ...mapActions({
      fetchUserAttestations: "fetchAttestations",
      getUser: "fetchAdminAdherentsById",
      getAdherent: "fetchAdherent",
      add: "storeAttestations",
      delete: "destroyAttestation",
      update: "updateAttestation",
      callPdfGenerator: "callPdfGenerator",
    }),
  },
};
</script>
<style scoped>
.mrgin_btm {
  margin-bottom: 3%;
}
</style>

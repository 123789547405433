<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col :span="22">
        <a-spin tip="Chargement..." :spinning="processing">
          <a-table
            bordered
            v-if="attestations.length"
            :dataSource="attestations"
            :columns="columns"
            :rowKey="id"
          >
            <template slot="row_index" slot-scope="text, record, index">
              <p>{{ index + 1 }}</p>
            </template>
            <template slot="attestation" slot-scope="text, record"
              >{{ record.type_attestation.nom_fr }}
            </template>

            <template slot="etat" slot-scope="text, record">
              <a
                v-if="record.etat === ETAT_DEMANDE_ATTESTATION_EN_ATTENTE"
                style="color:orange"
                >En cours</a
              >
              <a v-else>Prête</a>
            </template>

            <template slot="dt_demande" slot-scope="text, record"
              >{{ record.created_at | date }}
            </template>
            <template slot="operation" slot-scope="text, record">
              <a-popconfirm
                v-if="record.etat !== ETAT_DEMANDE_ATTESTATION_ACCEPTER"
                title="Voulez-vous valider et notifier cette demande d'attestation ?"
                @confirm="validateAttestation(record)"
              >
                <a-tooltip>
                  <a-icon
                    type="bell"
                    class="action_icons"
                    theme="twoTone"
                    twoToneColor="#52c41a"
                  />
                  <template slot="title"
                    >Valider et notifier l'adherent</template
                  >
                </a-tooltip>
              </a-popconfirm>
              <a-tooltip
                placement="top"
                v-if="record.etat === ETAT_DEMANDE_ATTESTATION_ACCEPTER"
              >
                <template slot="title">
                  <span>Imprimer</span>
                </template>
                <a-icon
                  type="printer"
                  @click="getDocument(record)"
                  class="action_icons"
                  theme="twoTone"
                />
              </a-tooltip>
              <a-popconfirm
                v-if="!record.etat"
                title="Etes-vous sûr de vouloir annuler la demande ?"
                @confirm="() => onDelete(record.id)"
              >
                <a-icon
                  type="delete"
                  class="action_icons decission_btn"
                  theme="twoTone"
                  twoToneColor="#eb2f96"
                />
              </a-popconfirm>
            </template>
            <div
              v-if="record.observation"
              slot="expandedRowRender"
              slot-scope="record"
              style="margin: 0"
            >
              <b> Observation : </b>{{ record.observation }}
            </div>
            <span v-else>Pas d'observation</span>
          </a-table>
          <EmptyResult v-else />
        </a-spin>
      </a-col>
    </a-row>
    <necessary-paiement-message :visible="paiementErrorMessageModalVisible" />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
// import  debounce  from "lodash-es/debounce";
import NecessaryPaiementMessage from "@/components/common/NecessaryPaiementMessage";

// import SearchComponent from "@/components/common/Search";
import EmptyResult from "@/components/common/EmptyResult";
import constants from "@/const/const";

const columns = [
  {
    title: "N°",

    scopedSlots: { customRender: "row_index" },
  },
  {
    title: "Attestation",
    scopedSlots: { customRender: "attestation" },
  },
  {
    title: "Organisme",
    dataIndex: "organisme.nom_fr",
  },
  {
    title: "Date demande",
    scopedSlots: { customRender: "dt_demande" },
  },
  {
    title: "Etat",
    scopedSlots: { customRender: "etat" },
  },
  {
    title: `${constants.OPERATION}`,
    scopedSlots: { customRender: "operation" },
  },
];
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 12,
      offset: 0,
    },
    sm: {
      span: 12,
      offset: 20,
    },
  },
};

export default {
  name: "AttestationList",
  created() {},
  components: {
    // SearchComponent,
    EmptyResult,
    NecessaryPaiementMessage,
  },
  data() {
    return {
      confirmDirty: false,
      autoCompleteResult: [],
      formItemLayout: formItemLayout,
      tailFormItemLayout: tailFormItemLayout,
      columns: columns,
      newDemandeAttestationComponentKey: 0,
      visible: false,
      paiementErrorMessageModalVisible: false,
      confirmLoading: false,
    };
  },
  props: {
    attestations: Array,
    processing: {
      type: Boolean,
      default: () => false,
    },
    user: {
      type: Object,
      default: () => null,
    },
    isAdmin: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapState({
      organismes: (state) => state.adherent_organismes.organismes,
    }),
  },
  methods: {
    closePaiementErrorModal() {
      this.paiementErrorMessageModalVisible = false;
    },
    showPaiementErrorModal() {
      this.paiementErrorMessageModalVisible = true;
    },
    validateAttestation(attestation) {
      if (!this.isUserPaiementSettled(this.user)) {
        this.displayPaiementMandatoryError();
        return;
      }
      this.$emit("validate", attestation.id);
    },
    getDemandeAttestationComponentKey() {
      this.newDemandeAttestationComponentKey = this.getRandomInt();
    },
    onDelete(id) {
      this.$emit("delete", id);
    },
    moment,
    getDocument(record) {
      this.$emit("print", record.id);
    },
    closeModal() {
      this.visible = false;
      this.getDemandeAttestationComponentKey();
    },
    showModal() {
      if (!this.isUserPaiementSettled(this.user)) {
        this.displayPaiementMandatoryError();
        return;
      }
      this.visible = true;
    },
    displayPaiementMandatoryError() {
      this.closePaiementErrorModal();
    },

    handleCancel(e) {
      this.visible = false;
    },
    handleClose(e) {
      this.visible = false;
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    ...mapActions({
      fetchUserAttestations: "fetchAdherentAttestations",
      getAdherent: "fetchAdherent",
      add: "storeAdherentAttestations",
      delete: "destroyAdherentAttestation",
      callPdfGenerator: "callPdfGenerator",
    }),
  },
};
</script>
<style scoped>
.txt-end {
  text-align: end;
}
.table_head {
  margin-bottom: 2%;
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
.decission_btn {
  text-decoration: underline;
}
.action_icons {
  margin: 4px;
  margin-left: 20px;
  font-size: large;
}
</style>

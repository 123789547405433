<template>
  <a-modal
    title="Nouvelle demande"
    :visible="visible"
    @cancel="handleCancel"
    okText="Valider"
    @ok="handleOk"
    :okButtonProps="{ props: { htmlType: 'submit' } }"
  >
    <a-form
      :layout="formLayout"
      id="nouveau_demande_attestation_form"
      name="add_attestation_form"
      class="adherent_form"
      :form="form"
    >
      <a-row type="flex" justify="center" align="middle">
        <a-col :span="16">
          <a-row type="flex" justify="space-around" align="middle">
            <a-col v-if="isAdmin" :span="24">
              <a-form-item label="Réf" v-bind="formItemLayout">
                <a-input v-decorator="['ref']"/>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item v-bind="formItemLayout" label="Type">
                <a-select
                    @change="handleTypeChange"
                    v-decorator="[
                    'type_attestation_id',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Le type est obligatoire!'
                        }
                      ]
                    }
                  ]"
                  :placeholder="CHOISIR_TYPE"
                >
                  <a-select-option
                    v-for="type_attestation in _types_attestations"
                    :key="type_attestation.id"
                    >{{ type_attestation.nom_fr }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-row type="flex" justify="center" align="middle">
                <a-col :span="23">
                  <a-form-item
                    v-bind="{
                      labelCol: {
                        xs: { span: 24 },
                        sm: { span: 8 }
                      },
                      wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 14 }
                      }
                    }"
                    label="Organisme"
                  >
                    <a-select
                      @change="handleOrganismeChange"
                      v-decorator="[
                        'organisme_id',
                        {
                          rules: [
                            {
                              required: isOrganismeRequired,
                              message: 'L\'organisme est obligatoire!'
                            }
                          ]
                        }
                      ]"
                      placeholder="Organisme"
                      style="margin-right : 2px"
                    >
                      <a-select-option
                        v-for="organisme in local_organismes"
                        :key="organisme.id"
                        >{{ organisme.nom_fr }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="1">
                  <a-popover placement="topLeft">
                    <template v-if="selected_organisme_id" slot="content">
                      <textarea :disabled="true" :value="getAvantage" />
                    </template>
                    <span slot="title">Les avantages</span>
                    <a-icon type="question-circle" />
                  </a-popover>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="24">
              <a-form-item v-bind="formItemLayout" label="Observation">
                <a-textarea v-decorator="['observation']"></a-textarea>
              </a-form-item>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-form>
    <template slot="footer">
      <a-button
              :loading="processing"
              @click="handleOk"
              key="submit"
              type="primary"
      >Valider
      </a-button
      >
    </template>
  </a-modal>
</template>
<script>
import {mapActions, mapState} from "vuex";
import constants from "@/const/const";
import _ from "lodash-es";

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8}
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16}
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 12,
      offset: 0
    },
    sm: {
      span: 12,
      offset: 20
    }
  }
};
export default {
  name: "AttestatioEditor",
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  created() {
    this.getAdditioanlData();
  },
  data() {
    return {
      formItemLayout,
      tailFormItemLayout,
      selected_organisme_id: null,
      CHOISIR_TYPE: constants.CHOISIR_TYPE,
      local_organismes: this.organismes
    };
  },
  props: {
    processing: {type: Boolean, default: () => false},
    visible: {type: Boolean, default: () => false},
    isAdmin: {type: Boolean, default: () => false},
  },
  computed: {
    getAvantage() {
      let selected_organisme_id = this.selected_organisme_id;
      let organisme = _.find(this.organismes, function(o) {
        return o.id === selected_organisme_id;
      });

      return organisme ? organisme.avantage : "Aucun";
    },
    ...mapState({
      _types_attestations: state => state.types_attestations.type_attestations,
      organismes: state => state.adherent_organismes.organismes
    }),
    isOrganismeRequired() {
      return this.local_organismes && this.local_organismes.length;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    handleCancel() {
      this.$emit("cancel");
    },
    handleOk() {
      this.$emit("create");
    },
    setAttestationType() {},
    handleTypeChange(value) {
      this.local_organismes = this.organismes.filter(item => this.get(item, 'type_attestation.id') === value);
      this.$nextTick(() => {
        this.form.validateFields(["organisme_id"], {force: true});
      });
    },
    handleOrganismeChange(value) {
      this.selected_organisme_id = value;
    },
    ...mapActions({
      getAdditioanlData: "fetchTypeAttestations"
    })
  }
};
</script>
